<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-error mb-4" v-if="isSync">
      You can't add a floorplan because it comes from an external api
    </div>
    <div class="form-row">
      <div class="form-col">
        <label for="unit_number">unit number</label>
        <text-input
          type="number"
          id="unit_number"
          v-model="unit.unit_name"
          :disabled="isSync"
        />
        <span v-if="hasError('unit.unit_name')" class="form-error">{{ showError('unit.unit_name') }}</span>
      </div>
      <div class="form-col">
          <label for="starting_price">starting price</label>
          <AmountInput
            v-model="unit.starting_price"
            id="starting_price"
            prefix="$"
            amountClass="form-input outline"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.starting_price')" class="form-error">{{ showError('unit.starting_price') }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label for="available_date">available date</label>
        <DatePicker
          name="available_date"
          :value="unit.available_date"
          @change="changeDates"
          :disabled="isSync"
        >
        </DatePicker>
      </div>
      <div class="form-col">
          <label for="bedrooms">bedrooms</label>
          <text-input
            type="number"
            v-model="unit.bedrooms"
            id="bedrooms"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.bedrooms')" class="form-error">{{ showError('unit.bedrooms') }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
          <label for="public_floorplan_name">floorplan name</label>
          <text-input
            v-model="unit.floorplan_name"
            id="public_floorplan_name"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.floorplan_name')" class="form-error">{{
            showError('unit.floorplan_name')
          }}</span>
      </div>
      <div class="form-col">
          <label for="bathrooms">bathrooms</label>
          <text-input
            v-model="unit.bathrooms"
            type="number"
            id="bathrooms"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.bathrooms')" class="form-error">{{ showError('unit.bathrooms') }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
          <label for="internal_floorplan_name">unit type</label>
          <text-input
            v-model="unit.unit_type"
            id="internal_floorplan_name"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.unit_type')" class="form-error">{{ showError('unit.unit_type') }}</span>
      </div>
      <div class="form-col">
          <label for="square_footage">square footage</label>
          <text-input
            v-model="unit.size_square_feet"
            type="number"
            id="square_footage"
            :disabled="isSync"
          />
          <span v-if="hasError('unit.size_square_feet')" class="form-error">{{ showError('unit.size_square_feet') }}</span>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="form-col">
        <file-manager
          main-directory="floorplan"
          :max-upload-size="20"
          :max-nb-of-files="nbImages"
          :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
          v-model="unit.floor_plan_image_link"
          :library="getLibraryImageFilesByBaseDirectory('floorplan')"
          :key="refreshImage"
          :libraryDisabled="isSync"
        ></file-manager>
        <label>add floorplan image</label>
      </div>
    </div>

    <modal-footer :footer="footer"
    :primary="() => {
      if(!isSync) save()
    }"
    :secondary="() => {
      if(!isSync) saveNew()
    }"
    :tertiary="cancel" ></modal-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import DatePicker from "@/components/ui/datepicker/DatePicker";
import FileManager from "@/components/ui/filemanager/FileManager"
import TextInput from '@/components/ui/TextInput';
import AmountInput from "@/components/ui/AmountInput";
export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, TextInput],
  components: {
    Loader,
    ModalFooter,
    DatePicker,
    TextInput,
    FileManager,
    AmountInput
  },
  computed: {
    ...mapGetters({
      getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
      getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
      getAllLibraryFiles: 'files/getAllLibraryFiles'
    }),
  },
  data() {
    return {
      loading: false,
      imagestoUpload: '',
      footer: {
          primaryButton: 'save and close',
          tertiaryButton: 'cancel',
          secondaryButton: 'save and add new'
      },
      nbImages: 1,
      unit: {
        community_id: '',
        customer_id: '',
        unit_name: '',
        available_to_lease: true,
        available_for_movein: true,
        available_date: null,
        starting_price: null,
        unit_type: '',
        floorplan_name: '',
        bedrooms: null,
        bathrooms: null,
        size_square_feet: null,
        floor_plan_image_link: ''
      },
      refreshImage: false,
      isSync: false,
    }
  },
  validations: {
    'unit.unit_name': 'required | integer | only_positive_numbers',
    'unit.starting_price': 'required | numeric',
    'unit.unit_type': 'required | is_empty',
    'unit.bedrooms': 'required | integer | only_positive_numbers',
    'unit.bathrooms': 'required | integer | only_positive_numbers',
    'unit.size_square_feet': 'required | integer | only_positive_numbers',
    'unit.floorplan_name': 'is_empty'
  },
  watch: {
    community: function () {
      if (this.community?.id )
        this.fetchCommunity()
      else
        this.notifyError('please select a community to continue, then refresh the browser');
    }
  },
  methods: {
    save() {
      if (this.validator.passes()) {
        this.loading = true;
        this.unit.floor_plan_image_link = this.unit.floor_plan_image_link.length ? this.getThumbnailUrlByFileId(this.unit.floor_plan_image_link[0]) : null;
        this.$dhDataProvider.create('unit', {data: {
          ...this.unit,
          community_id: this.community?.id,
          customer_id: this.profile?.customerId,
          startingPrice: `${this.unit.starting_price}`,
          bedrooms: Number(this.unit.bedrooms),
          bathrooms: Number(this.unit.bathrooms),
          size_square_feet: Number(this.unit.size_square_feet),
          floor_plan_image_link: this.unit.floor_plan_image_link
        }})
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.notifySuccess('the unit has been created successfully.');
            this.$router.push({name: 'availability_pricing.index'})
          }
        })
        .catch((error) => (
          this.notifyError(error.message),
          this.loading = false))
      }
    },
    saveNew() {
      if (this.validator.passes()) {
        this.loading = true;
        this.unit.floor_plan_image_link = this.unit.floor_plan_image_link.length ? this.getThumbnailUrlByFileId(this.unit.floor_plan_image_link[0]) : null;
        let sp = this.unit.starting_price;
        if (sp) {
          const payloadString = sp.toString();
          if (payloadString.includes('.')) {
            const payloadSplit = payloadString.split('.');
            const hasOneDecimal = payloadSplit[1].length === 1
            sp = hasOneDecimal ? `${sp}0` : sp;
          } else {
            sp = `${this.unit.starting_price}.00`;
          }
        }
        this.$dhDataProvider.create('unit', {data: {
          ...this.unit,
          community_id: this.community?.id,
          customer_id: this.profile?.customerId,
          startingPrice: sp,
          bedrooms: Number(this.unit.bedrooms),
          bathrooms: Number(this.unit.bathrooms),
          size_square_feet: Number(this.unit.size_square_feet),
          floor_plan_image_link:  this.unit.floor_plan_image_link
        }})
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.unit = {
              community_id: '',
              customer_id: '',
              unit_name: '',
              available_to_lease: true,
              available_for_movein: true,
              available_date: null,
              starting_price: null,
              unit_type: '',
              floorplan_name: '',
              bedrooms: null,
              bathrooms: null,
              size_square_feet: null,
              floor_plan_image_link: ''
            }
            this.notifySuccess('the unit has been created successfully.');
            this.imagestoUpload = '';
            if (this.unit.floor_plan_image_link) this.unit.floor_plan_image_link = '';
            this.refreshImage = true
          }
        })
        .finally(() => {
          this.refreshImage = false
        })
        .catch((error) => (
          this.notifyError(error.message),
          this.loading = false))
      }
    },
    fetchCommunity() {
      this.loading = true;
      this.$dhDataProvider
        .get('communityByCustomer', { idCommunity: this.community?.id, idCustomer: this.profile?.customerId })
        .then((response) => {
          this.isSync = response.sync
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
         this.loading = false;
        })
    },
    cancel() {
      this.$router.push({name: 'availability_pricing.index'})
    },
    changeDates(date) {
      this.unit.available_date = date;
    }
  },
  created() {
    this.fetchCommunity();
    this.initValidator();
    this.validator.extend('only_positive_numbers', function(value) {
      return (/^-?[\d.]+(?:e-?\d+)?$/.test(Number(value))) && Number(value) > 0;
    });
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'unit.bedrooms.only_positive_numbers' : 'enter a valid value.',
      'unit.bathrooms.only_positive_numbers' : 'enter a valid value.',
      'unit.unit_name.only_positive_numbers': 'enter a valid value.',
      'unit.size_square_feet.only_positive_numbers' : 'enter a valid value.',
      'unit.unit_type.is_empty': 'this field is empty.',
      'unit.floorplan_name.is_empty': 'this field is empty.'
    })
  }
}
</script>
<style scoped>
.outline {
  outline: none !important;
}
</style>